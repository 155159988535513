
import React from "react"
import "./layout.css"

const Layout = ({ children }) => (
    <>
        {children}
        </>
)




export default Layout
